<template>
    
    <main>

        <div v-if="isSuccess" class = "container white successRow">
            <div class="row marginTop ">
                <Success title="Contraseña Recuperada con Exito" @goBack="isSuccess=false">
                    <RecoveredSuccess />
                </Success>
            </div>
        </div>
        
        <div class = "container grey darken-2 marginTop authTab" v-else>
            
            <div class="row margin" id = 'tabsRow' >
                <div class="col s12 center">
                    <h5 class="orange-text">Recuperar Contraseña</h5>
                </div>
                <RecoverPwForm @recovered="isSuccess=true"/>
            
            </div>

        </div>

    </main>

</template>

<script>

import RecoverPwForm from '@/components/Auth/RecoverPwForm'
import RecoveredSuccess from '@/components/Auth/RecoveredSuccess'
import Success from '@/components/UI/Success'
import useBoilerplate from '@/composables/useBoilerplate';

import { ref } from '@vue/runtime-core'

export default {
    components: { RecoverPwForm, Success, RecoveredSuccess },
    setup(){

        const { isSuccess } = useBoilerplate(false);

        return { isSuccess }
    }
}
</script>

<style scoped>

    .authTab{
        margin-top: 50px;
        max-width: 360px;
        border: 1px solid grey;
    }

    .successRow{
        max-width: 500px;
    }

</style>