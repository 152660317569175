<template>
  <div class="col s12 marginTop" id = "logInTab">
      
    <form @submit.prevent="recoverPW">
      
      <div class = "input-field col s12">
        <input type = "email" v-model="user" required>
        <label for = "user" class ='grey-text'><i class="far fa-envelope"></i> E-Mail</label>
      </div>
      
      <div class="col s12 center">
          <Loader v-if="isLoading" />
          <Error :warning="error" v-if="error!=null" />
      </div>
      
      
      <div class="col s12 center-align">
        <button class = "btn white-text orange marginTop">
            <i class = "fas fa-check"></i>
        </button>
      </div>
    
    </form>

  </div>
    
</template>

<script>

import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { ref } from '@vue/reactivity';
import { post } from '@/composables/httpCalls';
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Loader, Error },
    emits: ['recovered'],

    setup(props, context){
        
        const user = ref('');
        const { isLoading, error } = useBoilerplate(false);

        const recoverPW = async () => {
            
          isLoading.value = true;
          error.value = null;
          
          const url = "/api/recoverPW";
          
          const vars = {
            username: user.value.toLowerCase().replace(/ /gi, "")
          }

          try{

            const res = await post(url, vars);
            
            if(res.status == 200){
              return context.emit('recovered');
            }

            else if(res.status == 404){
                isLoading.value = false;
                return error.value = "El Usuario No Existe";
            }

            else{
                isLoading.value = false;
                return error.value = "Error de Conexion";
            }

          }catch(err){
            console.log(err);
            error.value = "Error de Sistema"
            isLoading.value = false;
          }

        }

        return { isLoading, user, recoverPW, error };
    }
}

</script>