<template>

        <h1 class = "margin">
            <i class="fas fa-envelope grey-text"></i>
        </h1>
        <h6 class="grey-text">
            Revise Su Correo para Visualizar la Nueva Contraseña
        </h6>
    
</template>

<style scoped>
    .fa-user-check{
        font-size: 120px;
    }
</style>